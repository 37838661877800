// Page.js
import React, { useState, useEffect } from 'react';
import './custom-styles.css';
import './faq-styles.css';
import './responsive.css';
import ContactForm from './ContactForm';

const Page = () => {
    
  const storedIndex = localStorage.getItem('currentIndex');
  const indexToUse = storedIndex ? parseInt(storedIndex, 10) : 0;
    
  const [data, setData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(indexToUse);
  const [stories, setStories] = useState([]);
  const [clickedStoryIndex, setClickedStoryIndex] = useState(null);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const IMG_DOMAIN_URL = 'https://devadmin-hope.lifenethealth.org/';

  const parseHTMLContent = (htmlString) => ({ __html: htmlString });

  const decodeTitle = (encodedTitle) => encodedTitle.replace(/&quot;/g, '"');

console.log(data);
    
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch main data
        
       
        const response = await fetch('https://devadmin-hope.lifenethealth.org/hope-is-our-thing-api');
        const apiData = await response.json();

        const filteredData = apiData.filter(
          (item) =>
            item ||
            item.title ||
            item.body ||
            item.field_page_name ||
            item.field_page_sub_title ||
            item.field_paragraph_description ||
            item.field_background_image_upload
        );

        setData(filteredData);

        // Fetch event data
        const eventResponse = await fetch('https://devadmin-hope.lifenethealth.org/hope-is-our-thing-events-api');
        const eventApiData = await eventResponse.json();

        setEventData(eventApiData);
        
        // Fetch stories data
        const storiesResponse = await fetch('https://devadmin-hope.lifenethealth.org/hope-is-our-thing-our-stories-api');
        const storiesData = await storiesResponse.json();

        setStories(storiesData);
        
        // Set current index based on URL
        const urlPath = window.location.pathname.replace('/', '');
        const indexFromUrl = filteredData.findIndex(item => item.field_url_path === urlPath);

        if (indexFromUrl !== -1) {
          setCurrentIndex(indexFromUrl);
        }
      
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    return () => {
      
    };

  }, [currentIndex]);


    const handleWheel = (event) => {
        if (!isScrolling && event.isTrusted) {
          // Check if the current index is 1 and the scroll direction is upward (delta < 0)
          // If true, prevent scrolling to a lower index
          if (!(currentIndex === 1 && Math.sign(event.deltaY) < 0)) {
            setIsScrolling(true);

            const delta = Math.sign(event.deltaY);
            const newIndex = delta > 0 ? currentIndex + 1 : currentIndex - 1;

            if (newIndex >= 0 && newIndex < data.length) {
              setCurrentIndex(newIndex);

              try {
                const currentItem = data[newIndex];
                const urlPath = currentItem.field_url_path;

                // Update the URL using the field_url_path value
                window.history.pushState({}, '', `/${urlPath}`);
              } catch (error) {
                console.error('Error handling wheel scroll:', error);
              }
            }

            setTimeout(() => {
              setIsScrolling(false);
            }, 100);
          } else {
              //Home Page
              setCurrentIndex(0);
              // Update the URL using the field_url_path value
              window.history.pushState({}, '', '/');
          }
        }
    };
  useEffect(() => {
    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [currentIndex, isScrolling]);

  const handleDotClick = (index) => {
//    event.stopPropagation();
    setCurrentIndex(index);
    localStorage.setItem('currentIndex', index);
    try {
      const currentItem = data[index];
      const urlPath = currentItem.field_url_path;

      // Update the URL using the field_url_path value
      window.history.pushState({}, '', `/${urlPath}`);
    } catch (error) {
      console.error('Error handling dot click:', error);
    }
  };
  const isMobile = windowWidth <= 1024;

  const HeaderComponent = () => {
        return (
          <div className="header-container">
            <div className="bdt-logo-img col-4">
              <a href="javascript:;" onClick={() => handleDotClick(0)}>
                <img
                  src="https://devadmin-hope.lifenethealth.org/sites/default/files/2024-02/Hope%20by%20LNH%20_Logo.png"
                  alt="logo.png"
                  title="HOPE IS OUR THING"
                  width="152"
                  height="73"
                />
              </a>
              <div class="bdt-mob-nav-menu">
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </div>
            </div>
            <div className="bdt-main-nav col-10">
              <ul>
                <li className="bdt-nav-list">
                  <a
                    className={`bdt-nav-link ${(currentIndex === 1 || currentIndex === 2) ? 'active' : ''}`}
                    href="javascript:;"
                    onClick={() => handleDotClick(1)}
                    title="WHY DONATE?"
                  >
                    WHY DONATE?
                  </a>
                </li>
                <li className="bdt-nav-list">
                  <a
                    className={`bdt-nav-link ${currentIndex === 3 ? 'active' : ''}`}
                    href="javascript:;"
                    onClick={() => handleDotClick(3)}
                    title="OUR STORIES"
                  >
                    OUR STORIES
                  </a>
                </li>
                <li className="bdt-nav-list">
                  <a
                    className={`bdt-nav-link ${(currentIndex === 4) ? 'active' : ''}`}
                    href="javascript:;"
                    onClick={() => handleDotClick(4)}
                    title="EVENTS"
                  >
                    EVENTS
                  </a>
                </li>
                <li className="bdt-nav-list">
                  <a
                    className={`bdt-nav-link ${currentIndex === 5 ? 'active' : ''}`}
                    href="javascript:;"
                    onClick={() => handleDotClick(5)}
                    title="ABOUT US"
                  >
                    ABOUT US
                  </a>
                </li>
                <li className="bdt-nav-list">
                  <a
                    className={`bdt-nav-link ${currentIndex === 6 ? 'active' : ''}`}
                    href="javascript:;"
                    onClick={() => handleDotClick(6)}
                    title="FAQS"
                  >
                    FAQS
                  </a>
                </li>
                <li className="bdt-nav-list">
                  <a
                    className={`bdt-nav-link ${currentIndex === 7 ? 'active' : ''}`}
                    href="javascript:;"
                    onClick={() => handleDotClick(7)}
                    title="FAQS"
                  >
                    CONTACT US
                  </a>
                </li>
                <li className="bdt-btn-register-donor">
                  <a
                    href="javascript:;"
                    onClick={() => handleDotClick(8)}
                    title="REGISTER TO BE A DONOR"
                  >
                    REGISTER TO BE A DONOR
                  </a>
                </li>
              </ul>
            </div>
            
          </div>
        );
      }; 

  const DataComponent = ({ item }) => {
    const BackgroundStyle = item && item.field_background_image_upload
      ? {
          backgroundImage: `url(${item.field_background_image_upload})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          height: '100vh',
          overflowY: 'scroll'
        }
      : {};
    const containerStyle = { backgroundColor: 'transparent' };

    return (
      <div className="sub-container" style={{ ...BackgroundStyle, ...containerStyle }}>
        <header className="header-main-container col-10">
          <HeaderComponent />
        </header>
        <main>
          <div
            className={`data-section-main main-data-container col-10 main-data-container-${
              item?.title?.toLowerCase()?.replace(/[^a-zA-Z0-9]/g, '') || ''
            }`}
            style={containerStyle}
          >
            <div className="data-section-sub-first sub-data-container-first">
              {item && item.body && (
                <div
                  className="data-section-body data-container"
                  dangerouslySetInnerHTML={parseHTMLContent(item.body)}
                />
              )}
            </div>
            {isMobile && currentIndex === 1 && (
                <div className="bdt-arrow-container bdt-down-arrow" onClick={() => handleDotClick(2)}>
                </div>
            )}
            {isMobile && currentIndex === 2 && (
                <div className="bdt-arrow-container bdt-up-arrow" onClick={() => handleDotClick(1)}>
                </div>
            )}
    
            <div className="data-section-sub-second sub-data-container-second col-6">
              {item && item.title.toLowerCase().trim() === 'our stories' && stories.length > 0 && (
                <div className="all-stories-container row bdt-our-stories-section">
                    <div className="bdt-our-stories-title">
                        <h2>Our Stories</h2>
                    </div>
                    <div className="row bdt-grid-row">
                        {stories.map((story, index) => (
                            <div key={index} className="story-preview column" data-video-url={story.field_video_url}>
                                <img src={story.field_our_stories_user_image} alt={`Story ${index}`} />
                                <p>{story.title}</p>
                            </div>

                        ))}
                    </div>
                            
                    <div className="bdt-modal-popup" id="bdt-modal-popup">
                        <div className="bdt-popup-head">
                            <button className="bdt-modal-popup-close">X</button>
                        </div>
                        <div className="bdt-popup-body">
                            <div class="bdt-iframe-video">
                                <iframe frameborder="0" loading="lazy" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
                            </div>
                        </div>
                    </div>
                    
                </div>
              )}
              {item && item.title.toLowerCase().trim() === 'events' && <EventComponent eventData={eventData} />}
              {item && item.title.toLowerCase().trim() === 'contact us' && <ContactForm />}
            </div>
          </div>
        </main>
        <footer>
        </footer>
      </div>
    );
  };

    const EventComponent = ({ eventData }) => {
      return (
        <div className="event-component">
          {eventData.map((event, index) => (
            <div key={index} className="event-display-container">
              <div class="bdt-event-details">
               <img src="https://devadmin-hope.lifenethealth.org/sites/default/files/2024-02/icons8-calendar-24_0.png" />
               <span>{event.field_event_date}</span>                
              </div>
              <a href={event.field_event_link}>{decodeTitle(event.title)}</a>
              <div class="bdt-event-details">
                <img src="https://devadmin-hope.lifenethealth.org/sites/default/files/2024-02/icons8-location-24.png" />
                <span>{event.field_event_address}</span>                
              </div>
            </div>
          ))}
        </div>
      );
    }; 
  return (
    <div>
      <div className={`main-container hope-is-our-thing`}>
          <DataComponent item={data[currentIndex]} />
        </div>
    </div>
  );
};

export default Page;

import Page from './components/Page';
//import ApiTest from './components/ApiTest';
import './App.css';

function App() {
  return (
    <div className="App">
      <Page />
    </div>
  );
}

export default App;
